import React from "react";
import Layout from "../../components/Layout";

const About = () => {
  return (
    <Layout>
      <div className="row">
        <div className="col col-8 col-d-10 push-d-1 push-2 col-m-12 push-m-0">
          <h1 className="text-center">About</h1>
          <p>
            My name is Jordyn Kaplan and I am currently a college student with a
            passion for creating healthy yet tasty foods. When diagnosed with a
            gluten allergy in middle school I started a YouTube channel creating
            recipes for various paleo sweets. My goal to share innovative yet
            simple recipes evolved into a food instagram, @jordynskitchen.
          </p>
          <p>
            Creating my new line of sauces and sweeteners is a fun way for me to
            share my passion with others! Each of my products are designed to be
            packed full of nutrient dense ingredients that serve a purpose along
            with tasting delicious!
          </p>
          <div style={{ height: "10rem" }} />
        </div>
      </div>
    </Layout>
  );
};

export default About;
