import React, { createContext, useReducer, useContext, useEffect } from "react";
import { CartReducer, sumItems } from "./CartReducer";
import { ProductsContext } from "./ProductsContext";

export const CartContext = createContext();

const storage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];
const initialState = {
  cartItems: storage,
  ...sumItems(storage),
  checkout: false,
};

const CartContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CartReducer, initialState);

  const increase = (payload) => {
    dispatch({ type: "INCREASE", payload });
  };

  const decrease = (payload) => {
    dispatch({ type: "DECREASE", payload });
  };

  const addProduct = (payload) => {
    dispatch({ type: "ADD_ITEM", payload });
  };

  const removeProduct = (payload) => {
    dispatch({ type: "REMOVE_ITEM", payload });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR" });
  };

  const handleCheckout = () => {
    dispatch({ type: "CHECKOUT" });
  };

  const contextValues = {
    removeProduct,
    addProduct,
    increase,
    decrease,
    clearCart,
    handleCheckout,
    ...state,
  };

  const { products } = useContext(ProductsContext);

  useEffect(() => {
    if (!products) return;

    state.cartItems
      .filter((item, index) => {
        let cartItemCopy = JSON.parse(JSON.stringify(item));

        delete cartItemCopy.quantity;

        return !products.some(
          (product) => JSON.stringify(product) === JSON.stringify(cartItemCopy)
        );
      })
      .forEach((item) => removeProduct(item));
  }, [products]);

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
