import React from "react";
import Header from "./Header";
// import Header from './shared/header';
import Footer from "../components/Footer";

// import { Helmet } from 'react-helmet-async';

// import 'bootswatch/dist/lux/bootstrap.css'
import "../scss/base.scss";
import { useWindowWidth, useWindowHeight } from "@react-hook/window-size";

const Layout = ({ title, description, footer, children }) => {
  const onlyWidth = useWindowWidth();
  const onlyHeight = useWindowHeight();

  let breakPoint = 800;

  return (
    <>
      <title>
        {title ? title + " - React Boilerplate" : "React.js Boilerplate"}
      </title>
      <meta name="description" content={description || "Jordyn's Kitchen"} />
      <Header />
      <div style={{ position: "relative" }}>
        {/* <img
          src="images/background.jpg"
          style={{
            position: "absolute",
            zIndex: -200,
            width: "100vw",
            height: "100vh",
          }}
        ></img> */}
      </div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            overflow: "hidden",
            zIndex: -100,
          }}
        >
          <div
            style={{
              width: onlyWidth < breakPoint ? `${breakPoint}px` : "2000px",
              position: "relative",
            }}
          >
            {onlyWidth < breakPoint ? (
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 1600 600"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlSpace="preserve"
                style={{
                  fillRule: "evenodd",
                  clipRule: "evenodd",
                  strokeLinejoin: "round",
                  strokeMiterlimit: 2,
                }}
              >
                <filter id="dropShadow">
                  <feDropShadow
                    dx="2"
                    dy="2"
                    stdDeviation="8"
                    floodOpacity="0.15"
                  ></feDropShadow>
                </filter>
                <g transform="matrix(1,0,0,1,-2.47316,33.3876)">
                  <path
                    d="M1629.43,123.723C1584.42,161.007 1535.39,201.817 1481.81,229.548C1436.84,252.823 1388.51,266.837 1336.34,255.438C1302.92,248.136 1271.44,229.687 1239.25,214.031C1194.04,192.042 1147.82,174.56 1098.79,175.509C1056.83,176.322 1020.05,189.021 983.893,204.509C948.736,219.57 914.762,239.709 869.625,235.919C825.522,232.217 784.603,210.274 743.184,191.727C685.828,166.043 627.907,145.412 566.708,151.414C502.889,157.672 442.433,182.253 381.689,204.848C333.995,222.588 286.163,239.506 234.349,235.438C195.589,232.394 161.079,216.246 126.519,200.908C79.973,180.252 33.47,160.316 -17.279,155.125C-50.222,151.755 -79.704,175.764 -83.074,208.707C-86.444,241.65 -62.434,271.132 -29.491,274.502C8.59,278.397 42.916,295.093 77.843,310.593C124.755,331.411 172.343,350.938 224.955,355.069C294.551,360.534 359.462,341.148 423.524,317.319C474.534,298.345 524.828,276.097 578.421,270.841C618.871,266.874 656.231,284.272 694.142,301.248C747.982,325.357 802.256,350.686 859.586,355.499C917.394,360.352 964.297,343.786 1009.48,324.226C1038.96,311.468 1067.03,296.147 1101.11,295.487C1131.29,294.902 1158.94,308.41 1186.77,321.945C1227.61,341.811 1268.32,363.406 1310.73,372.673C1462.36,405.803 1596.17,307.091 1705.98,216.137C1731.48,195.014 1735.04,157.159 1713.91,131.656C1692.79,106.154 1654.93,102.599 1629.43,123.723Z"
                    style={{
                      fill: "rgb(245,213,84)",
                      filter: "url(#dropShadow)",
                    }}
                  />
                </g>
                <g transform="matrix(1,0,0,1,-3.70974,39.5705)">
                  <path
                    d="M1597.5,13.195C1561.12,47.639 1521.49,86.916 1477.04,114.791C1441.91,136.83 1403.62,151.841 1360.59,144.122C1323.89,137.54 1289.93,120.105 1255.4,105.027C1208.95,84.744 1161.77,67.946 1110.78,67.926C1064.81,67.907 1023.48,83.43 982.777,101.076C955.951,112.706 929.689,126.041 900.073,127.884C850.051,130.998 799.626,117.828 754.11,97.735C707.474,77.146 660.819,51.932 608.584,50.286C544.465,48.264 484.011,66.632 423.903,87.441C376.109,103.988 328.63,122.455 278.004,124.961C235.862,127.047 196.27,112.097 156.385,99.4C101.239,81.845 45.825,67.184 -12.495,74.859C-52.289,80.097 -87.165,98.169 -123.73,112.875C-154.453,125.233 -169.363,160.208 -157.006,190.931C-144.649,221.654 -109.673,236.565 -78.95,224.207C-51.718,213.254 -26.474,197.734 3.164,193.833C43.662,188.503 81.69,201.555 119.985,213.746C173.539,230.794 227.351,247.616 283.937,244.814C345.954,241.744 404.613,221.108 463.162,200.838C509.399,184.83 555.48,168.671 604.803,170.226C641.494,171.383 672.889,193.052 705.647,207.513C768.505,235.263 838.447,251.952 907.527,247.653C943.734,245.399 977.256,233.893 1010.12,219.972C1042.7,206.169 1074.01,187.911 1110.73,187.926C1145.01,187.94 1176.15,201.364 1207.38,214.999C1250.52,233.839 1293.55,254.012 1339.4,262.237C1413.41,275.513 1480.37,254.353 1540.81,216.449C1591.93,184.385 1638.15,139.959 1680,100.338C1704.05,77.572 1705.09,39.565 1682.33,15.517C1659.56,-8.531 1621.55,-9.571 1597.5,13.195Z"
                    style={{
                      fill: "rgb(253,255,182)",
                      filter: "url(#dropShadow)",
                    }}
                  />
                </g>
                <g transform="matrix(1,0,0,1,4.5826,42.427)">
                  <path
                    d="M1584.8,-73.97C1550.58,-45.74 1511.98,-13.852 1469.85,8.651C1436.04,26.711 1399.91,39.084 1361.78,32.561C1330.55,27.22 1302.94,11.529 1274.58,-1.535C1232.79,-20.782 1190.03,-35.932 1141.67,-33.655C1085.85,-31.028 1035.66,-11.403 985.026,6.275C948.97,18.864 912.625,30.846 870.8,24.076C831.609,17.733 796.937,-3.199 761.347,-21.256C711.546,-46.521 660.599,-67.613 603.24,-66.825C550.47,-66.1 499.242,-49.544 448.579,-29.59C408.134,-13.66 368.103,4.637 326.991,12.833C279.774,22.245 246.933,10.807 212.537,-0.888C180,-11.952 146.484,-23.655 105.603,-29.139C62.036,-34.984 29.423,-31.872 0.332,-27.313C-45.138,-20.188 -77.807,-8.087 -160.904,-57.486C-189.369,-74.407 -226.217,-65.036 -243.139,-36.571C-260.06,-8.106 -250.689,28.743 -222.224,45.664C-113.284,110.426 -60.629,104.462 -4.132,95.092C21.708,90.806 48.816,84.318 89.648,89.795C131.603,95.423 163.842,110.007 197.2,120.369C242.221,134.355 288.587,142.849 350.45,130.517C398.382,120.962 445.4,100.634 492.554,82.062C529.494,67.513 566.411,53.692 604.888,53.164C642.271,52.65 674.597,69.293 707.053,85.76C753.715,109.433 800.245,134.218 851.626,142.534C914.75,152.751 970.164,138.568 1024.58,119.569C1064.42,105.66 1103.39,88.279 1147.31,86.212C1183.12,84.526 1212.9,102.047 1243.82,116.561C1275.16,131.274 1307.01,144.937 1341.54,150.843C1461.76,171.408 1574.56,90.034 1661.16,18.603C1686.7,-2.467 1690.34,-40.315 1669.27,-65.861C1648.2,-91.407 1610.35,-95.041 1584.8,-73.97Z"
                    style={{
                      fill: "rgb(255,255,143)",
                      filter: "url(#dropShadow)",
                    }}
                  />
                </g>
                <path
                  d="M1488.4,-58.87C1410.63,-7.195 1354.78,-13.131 1293.16,-24.157C1239.04,-33.844 1181.37,-46.599 1109.32,-43.759C1028.71,-40.582 949.573,-16.709 868.434,-20.789C825.242,-22.961 783.646,-36.616 741.426,-45.03C690.096,-55.261 637.929,-59.234 585.644,-59.201C474.718,-59.129 366.347,-33.323 255.097,-33.499C123.61,-33.706 -12.399,-74.039 -142.642,-44.432C-174.933,-37.092 -195.189,-4.916 -187.849,27.375C-180.509,59.666 -148.333,79.923 -116.042,72.582C5.468,44.961 132.237,86.307 254.908,86.501C366.247,86.677 474.707,60.871 585.722,60.799C630.103,60.771 674.399,63.97 717.97,72.655C765.914,82.211 813.36,96.593 862.408,99.06C947.135,103.32 1029.88,79.465 1114.04,76.147C1193.87,73.001 1253.62,92.9 1312.42,100.282C1386.53,109.584 1459.43,104.46 1554.81,41.076C1582.39,22.749 1589.9,-14.523 1571.58,-42.104C1553.25,-69.685 1515.98,-77.197 1488.4,-58.87Z"
                  style={{ fill: "rgb(255,255,143)" }}
                />
              </svg>
            ) : (
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 6880 1040"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlSpace="preserve"
                style={{
                  fillRule: "evenodd",
                  clipRule: "evenodd",
                  strokeLinejoin: "round",
                  strokeMiterlimit: 2,
                }}
              >
                <filter id="dropShadow">
                  <feDropShadow
                    dx="2"
                    dy="2"
                    stdDeviation="8"
                    floodOpacity="0.3"
                  ></feDropShadow>
                </filter>

                <path
                  d="M6915.13,174.614C6824.36,223.568 6736,275.528 6637.11,306.676C6347.23,397.983 6050.15,379.684 5752.34,340.581C5435.67,299.002 5118.06,233.778 4806.38,233.806C4592.54,233.826 4385.76,279.797 4180.12,325.765C3989.31,368.42 3799.57,411.22 3604.94,408.24C3422.31,405.443 3241.64,353.806 3060.73,309.545C2860.65,260.591 2660.32,220.38 2458.15,245.06C2093.33,289.595 1753.46,473.5 1385.3,485.051C1131.07,493.027 918.475,393.84 692.655,330.997C436.963,259.84 167.074,227.896 -177.889,388.903C-227.901,412.245 -249.554,471.8 -226.211,521.812C-202.869,571.824 -143.314,593.477 -93.302,570.134C258.386,405.989 518.358,483.33 775.69,564.999C972.107,627.336 1167.57,691.98 1391.57,684.952C1765.71,673.214 2111.64,488.844 2482.38,443.586C2660.6,421.83 2836.82,460.661 3013.2,503.815C3208.76,551.66 3404.47,605.193 3601.87,608.216C3812.22,611.437 4017.54,567.046 4223.75,520.948C4415.15,478.165 4607.37,433.825 4806.39,433.806C5109.54,433.778 5418.31,498.439 5726.3,538.879C6053.16,581.795 6379.05,597.649 6697.2,497.437C6808.23,462.463 6908.15,405.611 7010.07,350.645C7058.64,324.446 7076.81,263.738 7050.61,215.161C7024.41,166.584 6963.7,148.416 6915.13,174.614Z"
                  style={{
                    fill: "rgb(245,213,84)",
                    filter: "url(#dropShadow)",
                  }}
                />
                <path
                  d="M6866.3,21.233C6740.98,96.607 6603.8,172.128 6460.49,205.686C6244.84,256.179 6030.5,224.458 5813.68,198.992C5480.02,159.804 5140.84,95.297 4804.39,92.027C4598.58,90.027 4403.78,133.044 4209.89,177.113C4030.94,217.784 3852.83,259.504 3665.76,258.417C3470.52,257.282 3278.8,213.697 3086.62,175.806C2878.78,134.824 2670.46,100.323 2457.82,120.485C2085.45,155.792 1745.16,319.871 1367.71,331.914C1150.59,338.842 941.993,278.345 733.034,225.416C502.383,166.993 271.386,117.334 31.239,151.626C-23.398,159.428 -61.422,210.12 -53.621,264.757C-45.819,319.395 4.874,357.419 59.511,349.617C273.327,319.086 478.565,367.276 683.926,419.293C910.979,476.805 1138.16,539.34 1374.09,531.813C1755.77,519.634 2100.17,355.294 2476.7,319.592C2669.93,301.27 2859.05,334.786 3047.93,372.028C3252.49,412.36 3456.78,457.206 3664.6,458.413C3867.22,459.591 4060.39,416.191 4254.22,372.139C4433.1,331.483 4612.57,290.172 4802.44,292.017C5131.8,295.218 5463.73,359.265 5790.35,397.627C6030.26,425.805 6267.47,456.29 6506.08,400.418C6669.33,362.194 6826.63,278.48 6969.38,192.622C7016.68,164.176 7031.98,102.683 7003.54,55.387C6975.09,8.091 6913.6,-7.213 6866.3,21.233Z"
                  style={{
                    fill: "rgb(253,255,182)",
                    filter: "url(#dropShadow)",
                  }}
                />
                <path
                  d="M6849.39,-118.293C6751.56,-66.204 6660.32,-0.564 6554.24,33.41C6330.62,105.019 6016.13,61.453 5689.24,50.324C5397.99,40.409 5127.07,-54.443 4832.79,-45.768C4424.44,-33.73 4042.76,129.816 3632.71,130.309C3453.16,130.526 3272.68,79.799 3097.64,46.788C2874.77,4.755 2631.68,-67.12 2405.23,-46.552C2248.11,-32.282 2106.93,37.944 1958.39,86.015C1801.6,136.754 1582.95,173.726 1417.57,173.859C1173.1,174.055 918.741,85.871 681.418,38.613C490.984,0.693 302.15,-41.156 107.757,-32.891C60.146,-30.867 21.633,-18.858 -14.828,-4.012C-44.191,7.944 -70.527,24.911 -108.803,26.135C-163.967,27.898 -207.32,74.116 -205.556,129.279C-203.793,184.443 -157.575,227.796 -102.412,226.033C-54.537,224.502 -15.797,212.712 20.913,197.859C50.656,185.825 77.397,168.58 116.252,166.928C294.627,159.345 467.618,199.966 642.359,234.762C892.136,284.499 1160.43,374.066 1417.73,373.859C1601.88,373.711 1845.39,332.797 2019.97,276.299C2154.42,232.787 2281.11,165.545 2423.32,152.628C2631.94,133.68 2855.25,204.599 3060.58,243.324C3247.7,278.614 3441,330.541 3632.95,330.309C4044.89,329.813 4428.45,166.239 4838.69,154.146C5128.65,145.598 5395.45,240.439 5682.43,250.209C6035.02,262.212 6374.04,301.119 6615.23,223.881C6732.74,186.25 6835.02,115.94 6943.38,58.243C6992.1,32.305 7010.59,-28.306 6984.65,-77.022C6958.72,-125.739 6898.11,-144.231 6849.39,-118.293Z"
                  style={{
                    fill: "rgb(255,255,143)",
                    filter: "url(#dropShadow)",
                  }}
                />
                <path
                  d="M6574.15,-95.904C6308.43,-78.906 6027.81,-56.116 5761.32,-69.757C5450.65,-85.659 5143.75,-137.016 4831.59,-133.066C4382.07,-127.378 3941.75,-24.576 3490.96,-34.836C3368.37,-37.626 3245.34,-38.953 3123.63,-55.004C2935.91,-79.76 2749.72,-120.54 2559.52,-121.033C2200.86,-121.961 1852.1,-25.669 1496.77,-3.642C1237.97,12.401 983.909,-14.463 726.285,-28.989C491.437,-42.231 255.979,-43.863 21.245,-59.403C-33.826,-63.049 -81.497,-21.298 -85.142,33.773C-88.788,88.844 -47.037,136.514 8.034,140.16C243.418,155.743 479.527,157.415 715.026,170.694C980.544,185.665 1242.41,212.51 1509.14,195.975C1860.2,174.212 2204.65,78.049 2559,78.967C2740.61,79.437 2918.24,119.642 3097.48,143.28C3226.36,160.276 3356.6,162.157 3486.41,165.112C3939.56,175.426 4382.24,72.636 4834.12,66.918C5142.02,63.022 5444.67,114.297 5751.09,129.982C6025.11,144.008 6313.69,121.166 6586.92,103.688C6642,100.164 6683.86,52.587 6680.33,-2.492C6676.81,-57.571 6629.23,-99.428 6574.15,-95.904Z"
                  style={{
                    fill: "rgb(255,255,143)",
                  }}
                />
              </svg>
            )}
          </div>
        </div>
      </div>
      <main>
        <div style={{ height: "26vh" }} />
        <div className="container">
          <div className="fadein inview">{children}</div>
        </div>
      </main>

      {footer === false ? <div /> : <Footer />}
    </>
  );
};

export default Layout;
