import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";

const Image: React.FC<IImage> = ({
  width,
  height,
  src,
  alt,
  circle = false,
  style,
}) => {
  const handleLoad = () => {
    setLoaded(true);
  };

  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <img
        src={src}
        alt={alt}
        onLoad={() => {
          handleLoad();
        }}
        style={{
          visibility: loaded ? "visible" : "hidden",
          opacity: loaded ? 1 : 0,
          transition: "opacity 1s ease",
          height: loaded ? undefined : 0,
          lineHeight: loaded ? undefined : 0,
          ...style,
        }}
      />
      {!loaded && (
        <Skeleton
          style={{
            transform: "translate(0, -5px)",
            width: width ? width : "100%",
            height: height,
            visibility: loaded ? "hidden" : "visible",
            opacity: loaded ? 0 : 1,
            transition: "opacity 1s ease",
            borderRadius: circle ? "400px" : undefined,
            ...style,
          }}
        />
      )}
    </>
  );
};

interface IImage {
  width: number;
  height: number;
  src: string;
  alt: string;
  circle?: boolean;
  style: any;
}

export default Image;
